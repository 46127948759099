import ProjectsView from '../../components/Dashboard/Projects/ProjectsView/ProjectsView';

const Projects = () => {
    return (
        <>
            <ProjectsView />
        </>
    )
};

export default Projects;