import './Banner.css';
import React from 'react';

const Banner = () => {
    return (
        <section className="banner">
            <p>Local & High Quality Details</p>
            <p>Davis Mobile Detailing</p>
        </section>
    )
};

export default Banner;
