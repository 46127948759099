import './Navbar.css';
import { useState } from 'react';
import logo from '../../assets/images/logo.png';

const Navbar = ({ Link }) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <nav className="navbar">
            <div className="navbar-content-mobile">
                <img src={logo} alt="Logo" />
                <div className={(isActive ? "active" : "")} id="mobile-bars"
                    onClick={() => isActive ? setIsActive(false) : setIsActive(true)}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={"navbar-dropdown" + (isActive ? " active" : "")}>
                    <Link className="navbar-dropdown-link" to="/">HOME</Link>
                    <Link className="navbar-dropdown-link" to="/packages">PACKAGES</Link>
                    <Link className="navbar-dropdown-link" to="/contact">CONTACT</Link>
                </div>
            </div>
            <div className="navbar-content">
                <div className="navbar-logo">
                    <img src={logo} alt="Logo" />
                    <p><span>D</span>avis <span>M</span>obile <span>D</span>etailing</p>
                </div>
                <div className="navbar-links">
                    <Link className="navbar-link" to="/">HOME</Link>
                    <Link className="navbar-link" to="/packages">PACKAGES</Link>
                    <Link className="navbar-link" to="/contact">CONTACT</Link>
                </div>
            </div>
        </nav >
    )
};

export default Navbar;